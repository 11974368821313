
const gitInfo = {
  branch: "master",
  commit: "1627843",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/162784333f8752f8355072d20412a5bcc0267eeb",
  date: "15/10/2024",
};

export default gitInfo;
  